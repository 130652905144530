import * as React from 'react';

import {Slot} from '@radix-ui/react-slot';
import {LucideIcon} from 'lucide-react';
import {cn} from '~/components/utils';

type CardProps = React.HTMLAttributes<HTMLDivElement> & {asChild?: boolean};
const Card = React.forwardRef<HTMLDivElement, CardProps>(({className, asChild, ...props}, ref) => {
  const Comp = asChild ? Slot : 'div';
  return (
    <Comp
      ref={ref}
      className={cn('rounded-lg bg-card text-card-foreground shadow-xl relative', className)}
      {...props}
    />
  );
});
Card.displayName = 'Card';

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({className, ...props}, ref) => (
    <div ref={ref} className={cn('flex flex-col space-y-1.5 pb-6 pt-8 px-6', className)} {...props} />
  ),
);
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({className, ...props}, ref) => (
    <h3
      ref={ref}
      className={cn('text-2xl text-center z-20 font-semibold leading-none tracking-tight', className)}
      {...props}
    />
  ),
);
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({className, ...props}, ref) => (
    <p ref={ref} className={cn('text-sm text-center text-muted-foreground', className)} {...props} />
  ),
);
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({className, ...props}, ref) => <div ref={ref} className={cn('p-6 pt-0', className)} {...props} />,
);
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({className, ...props}, ref) => (
    <div ref={ref} className={cn('flex items-center gap-6 p-6 pt-0', className)} {...props} />
  ),
);
CardFooter.displayName = 'CardFooter';

type CardIconProps = React.HTMLAttributes<HTMLDivElement> & {
  icon: LucideIcon;
};
const CardIcon = React.forwardRef<HTMLDivElement, CardIconProps>(({className, icon: Icon, ...props}, ref) => (
  <div
    ref={ref}
    className={cn('absolute top-0 right-1/2 translate-x-1/2 -translate-y-1/2 p-6 bg-card rounded-full', className)}
    {...props}>
    <Icon size={56} strokeWidth={1} />
  </div>
));
CardIcon.displayName = 'CardIcon';

export {Card, CardContent, CardDescription, CardFooter, CardHeader, CardIcon, CardTitle};
